<template>
  <v-card :loading="is_loading" class="mx-auto rounded-sm pa-5">
    <div
      v-if="singleClass"
      class="d-flex flex-column flex-sm-row justify-space-between"
    >
      <span>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.classroomName") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ singleClass.classroomName }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
      <span>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.classroomLevel") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $_t(`attribute.${singleClass.classroomLevel}`) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
      <span>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.teacher") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ singleClass.teacherName }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
      <span>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.specifications") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{
                truncateString(
                  Object.values(singleClass.specifications)
                    .map((lesson) => lesson.specificationTitle)
                    .filter(
                      (value, index, array) => array.indexOf(value) === index
                    )
                    .join(", "),
                  50
                )
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
      <span>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.baseType") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $_t(`attribute.${singleClass.baseType}`) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
    </div>
    <div
      v-if="singleClass"
      class="d-flex flex-column flex-sm-row justify-space-between"
    >
      <!--      students-->
      <span>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.students") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $_t("attribute.see_more") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in singleClass.students"
                    :key="index"
                    @click="openStudentModal(item)"
                    link
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ `${item.studentFullName}` }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
      <!--      start dates -->
      <span>
        <CLassTimesModal
          :times="singleClass.times"
          @update="update"
          :single-class="singleClass"
        >
          <template v-slot:activator>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-1">
                  {{ $_t("attribute.start_dates") }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-btn color="primary" block outlined>{{
                    $_t("attribute.see_more")
                  }}</v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </CLassTimesModal>
      </span>
      <!--      presentation info -->
      <span>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.add_present_info") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-dialog max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $_t("attribute.edit") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $_t("attribute.add_present_info") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-for="(time, index) in singleClass.times"
                    :key="index"
                    @click="openPresentInfoModal(time)"
                    :disabled="
                      time.state === 'Cancel' || time.color === color.reserved
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title class="d-flex justify-space-between">
                        <span>
                          {{
                            `${$_t("attribute.classroomNumber")} : ${
                              time.classroomNumber
                            }`
                          }}
                        </span>
                        <span>
                          <v-icon
                            v-if="
                              time.status &&
                              time.changesConfirmedBySchedulingAdmin
                            "
                            color="green"
                            >mdi-check-all</v-icon
                          >
                          <v-icon
                            v-else-if="
                              time.status && time.changesConfirmedByTeacher
                            "
                            color="orange"
                            >mdi-check</v-icon
                          >
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          `${$_date(time.start * 1000)} => ${getMainTime(
                            time.start * 1000,
                            "h:mm a"
                          )} : ${getMainTime(time.end * 1000, "h:mm a")} `
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-dialog>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
      <!--      teaching day info -->
      <span>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.edit_teaching_day_info") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-dialog max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $_t("attribute.edit") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $_t("attribute.edit_teaching_day_info") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-for="(time, index) in singleClass.times"
                    :key="index"
                    @click="openTeachingInfoModal(time)"
                    :disabled="
                      time.state === 'Cancel' || time.color === color.reserved
                    "
                  >
                    <v-list-item-content>
                      <v-list-item-title class="d-flex justify-space-between">
                        {{
                          `${$_t("attribute.classroomNumber")} : ${
                            time.classroomNumber
                          }`
                        }}
                        <span>
                          <v-icon
                            v-if="
                              time.info &&
                              time.changesConfirmedBySchedulingAdmin
                            "
                            color="green"
                            >mdi-check-all</v-icon
                          >
                          <v-icon
                            v-else-if="
                              time.info && time.changesConfirmedByTeacher
                            "
                            color="orange"
                            >mdi-check</v-icon
                          >
                        </span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          `${$_date(time.start * 1000)} => ${getMainTime(
                            time.start * 1000,
                            "h:mm a"
                          )} : ${getMainTime(time.end * 1000, "h:mm a")} `
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-dialog>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
      <!--       download links -->
      <span>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.download_links") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $_t("attribute.download") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(link, index) in downloadLinks"
                    :key="index"
                    link
                    :href="link"
                    target="_blank"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ `${$_t("attribute.link")} : ${index + 1}` }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
    </div>
    <div
      v-if="singleClass"
      class="d-flex flex-column flex-sm-row justify-center"
    >
      <!--     reset session -->
      <span
        v-if="
          ['Admin', 'SchedulingAdmin', 'Teacher', 'TeamLeader'].includes(role)
        "
      >
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.resetSessionInfo") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-dialog max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $_t("attribute.resetSessionInfo") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(time, index) in singleClass.times"
                    :key="index"
                    @click="resetSessionInfo(time)"
                    :disabled="time.state === 'Cancel'"
                    link
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          `${$_t("attribute.classroomNumber")} : ${
                            time.classroomNumber
                          }`
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          `${$_date(time.start * 1000)} => ${getMainTime(
                            time.start * 1000,
                            "h:mm a"
                          )} : ${getMainTime(time.end * 1000, "h:mm a")} `
                        }}
                        <div v-if="time.message">
                          {{
                            `${$_t("attribute.confirmMessage")} ${time.message}`
                          }}
                        </div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="time.message">
                        {{ `${$_t("attribute.description")}: ${time.message}` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-dialog>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
      <!--      confirm by teacher-->
      <span
        v-if="
          ['Admin', 'SchedulingAdmin', 'Teacher', 'TeamLeader'].includes(role)
        "
      >
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.confirmedByTeacher") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-dialog max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $_t("attribute.confirmTime") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(time, index) in singleClass.times"
                    :key="index"
                    @click="
                      confirmTime({ ...time, role: 'teacher', type: 'confirm' })
                    "
                    :disabled="
                      time.changesConfirmedByTeacher || time.state === 'Cancel'
                    "
                    link
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          `${$_t("attribute.classroomNumber")} : ${
                            time.classroomNumber
                          }`
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          `${$_date(time.start * 1000)} => ${getMainTime(
                            time.start * 1000,
                            "h:mm a"
                          )} : ${getMainTime(time.end * 1000, "h:mm a")} `
                        }}
                        <div v-if="time.message">
                          {{
                            `${$_t("attribute.confirmMessage")} ${time.message}`
                          }}
                        </div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="time.message">
                        {{ `${$_t("attribute.description")}: ${time.message}` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-dialog>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
      <!--      confirm by admin-->
      <span v-if="['Admin', 'SchedulingAdmin'].includes(role)">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.confirmedByAdmin") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-dialog max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $_t("attribute.confirmTime") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(time, index) in singleClass.times"
                    :key="index"
                    @click="
                      confirmTime({ ...time, role: 'admin', type: 'confirm' })
                    "
                    :disabled="
                      time.changesConfirmedBySchedulingAdmin ||
                      time.state === 'Cancel'
                    "
                    link
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          `${$_t("attribute.classroomNumber")} : ${
                            time.classroomNumber
                          }`
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          `${$_date(time.start * 1000)} => ${getMainTime(
                            time.start * 1000,
                            "h:mm a"
                          )} : ${getMainTime(time.end * 1000, "h:mm a")} `
                        }}
                        <div v-if="time.message">
                          {{
                            `${$_t("attribute.confirmMessage")} ${time.message}`
                          }}
                        </div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="time.message">
                        {{ `${$_t("attribute.description")}: ${time.message}` }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-dialog>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
      <!--      revert to teacher-->
      <span v-if="['Admin', 'SchedulingAdmin'].includes(role)">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.revertToTeacher") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-dialog max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    block
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ $_t("attribute.revert") }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(time, index) in singleClass.times"
                    :key="index"
                    @click="
                      confirmTime({ ...time, role: 'admin', type: 'revert' })
                    "
                    :disabled="
                      !time.changesConfirmedByTeacher || time.state === 'Cancel'
                    "
                    link
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          `${$_t("attribute.classroomNumber")} : ${
                            time.classroomNumber
                          }`
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          `${$_date(time.start * 1000)} => ${getMainTime(
                            time.start * 1000,
                            "h:mm a"
                          )} : ${getMainTime(time.end * 1000, "h:mm a")} `
                        }}
                        <div v-if="time.message">
                          {{
                            `${$_t("attribute.confirmMessage")} ${time.message}`
                          }}
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-dialog>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
      <!--      fix confirmation of reserved classroom-->
      <span
        v-if="['Admin', 'FinancialAdmin', 'AcademicConsultant'].includes(role)"
      >
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              {{ $_t("attribute.fixReservation") }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-btn
                @click="fixFinancialConfirmation"
                color="primary"
                outlined
                block
                class="elevation-0 mr-3"
              >
                <div class="txt-purple">
                  {{ $_t("attribute.fixReservation") }}
                </div>
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </span>
    </div>
    <PresentInfoModal
      v-if="singleClass"
      :time="time"
      :students="singleClass.students"
      :is-show-modal="presentInfoModal"
      :readonly="time.changesConfirmedByTeacher"
      @close="close"
      @update="update"
    />
    <TeachingDayInfo
      :time="time"
      :is-show-modal="teachingInfoModal"
      @close="close"
      @update="update"
      :readonly="time.changesConfirmedByTeacher"
    />
    <ConfirmClassModal
      :is-show-modal="confirmModal"
      :time="time"
      @close="confirmModal = false"
      @update="update"
    />
    <EditStudentInfoModal />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getMainTime } from "@/tools/Utils";
import PresentInfoModal from "@/packages/admin/components/classRoom/details/modal/PresentInfoModal";
import TeachingDayInfo from "@/packages/admin/components/classRoom/details/modal/TeachingDayInfo";
import ConfirmClassModal from "@/packages/admin/components/classRoom/details/modal/ConfirmClassModal";
import EditStudentInfoModal from "@/packages/admin/components/classRoom/details/EditStudentInfoModal";
import CLassTimesModal from "@/packages/admin/components/classRoom/details/CLassTimesModal";
import { event_colors } from "@/packages/admin/schema/calender/COLORS";

export default {
  name: "ClassInformation",
  components: {
    CLassTimesModal,
    EditStudentInfoModal,
    ConfirmClassModal,
    TeachingDayInfo,
    PresentInfoModal,
  },
  computed: {
    ...mapGetters({
      singleClass: "class/getClass",
      is_loading: "loading/is_loading",
      role: "authentication/role",
    }),
    downloadLinks() {
      return this.singleClass.specifications.map(
        (s) => s.specificationDownloadLinks
      );
    },
  },
  data() {
    return {
      getMainTime,
      presentInfoModal: false,
      teachingInfoModal: false,
      confirmModal: false,
      time: {},
      color: event_colors,
    };
  },
  methods: {
    openPresentInfoModal(time) {
      this.time = time;
      this.presentInfoModal = true;
    },
    openTeachingInfoModal(time) {
      this.time = time;
      this.teachingInfoModal = true;
    },
    confirmTime(time) {
      console.log(time);
      this.time = time;
      this.confirmModal = true;
    },
    close() {
      this.presentInfoModal = false;
      this.teachingInfoModal = false;
    },
    async resetSessionInfo(time) {
      const res = await this.$actions.resetSessionInfo({
        classroomId: this.$route.params.id,
        availableTimeId: time.availableTimeId,
        start: time.start,
        end: time.end,
      });
      if (res) await this.update();
    },
    openStudentModal(item) {
      this.$router.push({ query: { studentPublicKey: item.studentPublicKey } });
      this.$store.commit("class/setStudent", item);
      this.$store.commit("dialog/setStudent", true);
    },
    async fixFinancialConfirmation() {
      await this.$actions.fixConfirmationOfReservedSessionsOfClassroom({
        classroomId: this.$route.params.id,
      });
    },
    async update() {
      await this.$actions.getSingleClass({
        id: this.$route.params.id,
      });
      await this.$actions.getPassedLessonsOfClass({
        classroomId: this.$route.params.id,
      });
    },
  },
};
</script>

<style scoped></style>
