<template>
  <v-row>
    <v-col>
      <ClassInformation />
    </v-col>
    <v-container>
      <router-view />
    </v-container>
  </v-row>
</template>

<script>
import ClassInformation from "@/packages/admin/components/classRoom/details/ClassInformation";
export default {
  name: "ClassLayout",
  components: { ClassInformation },
  methods: {
    async initClass() {
      const res = await this.$actions.getSingleClass({
        id: this.$route.params.id,
      });
      if (!res) return this.$router.push({ name: "admin.class_room" });
    },
  },
  mounted() {
    this.initClass();
  },
};
</script>

<style scoped></style>
