<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.confirmClass")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        {{
          time.type === "confirm"
            ? $_t("attribute.confirmClassSure")
            : $_t("attribute.confirmRevertClassSure")
        }}
      </v-card-title>
      <v-card-title>
        <v-list-item-content>
          <v-list-item-title>
            {{
              `${$_t("attribute.classroomNumber")} : ${time.classroomNumber}`
            }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{
              `${$_date(time.start * 1000)} => ${getMainTime(
                time.start * 1000,
                "h:mm a"
              )} : ${getMainTime(time.end * 1000, "h:mm a")} `
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-card-title>
      <v-card-title>
        <v-textarea
          outlined
          v-model="message"
          :label="$_t('attribute.confirmMessage')"
          hide-details
        />
      </v-card-title>

      <v-card-actions>
        <v-col cols="12">
          <div class="d-flex justify-end">
            <v-btn color="primary" text @click="close">
              {{ $_t("attribute.cancel") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="ml-4"
              @click="submit"
              :loading="is_loading"
            >
              {{ $_t("attribute.confirm") }}
            </v-btn>
          </div>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { getMainTime } from "@/tools/Utils";

export default {
  name: "ConfirmClassModal",
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isShowModal(val) {
      if (val) {
        console.log(this.time);
        this.message = this.time?.message || null;
      }
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      lessons: "admin/get_lessons",
      singleClass: "class/getClass",
      role: "authentication/role",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  data() {
    return {
      message: "",
      getMainTime,
    };
  },
  methods: {
    async submit() {
      try {
        let res = null;
        if (this.time.type === "confirm") {
          res = await this.$actions.editTimeDetailOfClass({
            classroomId: this.singleClass.classroomId,
            availableTimeId: this.time.availableTimeId,
            classroomNumber: this.singleClass.classroomNumber,
            message: this.message,
            start: this.time.start,
            end: this.time.end,
            changesConfirmedByTeacher:
              this.time.role === "teacher"
                ? true
                : this.time.changesConfirmedByTeacher,
            changesConfirmedBySchedulingAdmin:
              this.time.role === "admin"
                ? true
                : this.time.changesConfirmedBySchedulingAdmin,
          });
        } else {
          res = await this.$actions.editTimeDetailOfClass({
            classroomId: this.singleClass.classroomId,
            availableTimeId: this.time.availableTimeId,
            classroomNumber: this.singleClass.classroomNumber,
            message: this.message,
            start: this.time.start,
            end: this.time.end,
            changesConfirmedByTeacher: false,
            changesConfirmedBySchedulingAdmin: false,
          });
        }

        if (res) {
          this.$emit("update");
          this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.message = "";
      this.$emit("close");
    },
  },
};
</script>
