import { render, staticRenderFns } from "./ClassLayout.vue?vue&type=template&id=185422a7&scoped=true&"
import script from "./ClassLayout.vue?vue&type=script&lang=js&"
export * from "./ClassLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "185422a7",
  null
  
)

export default component.exports